







import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import RoomsTypeManagerService, { RoomsTypeManagerServiceS } from '@/modules/rooms-type-manager/rooms-type-manager.service';
import ConfirmPopup from '@/modules/common/components/popup-confirm.vue';

@Component({
    components: {
        ConfirmPopup,
    },
})
export default class RoomsTypeConfirmClosePopup extends Vue {
    @Inject(RoomsTypeManagerServiceS) protected hotelsRoomTypeService!: RoomsTypeManagerService;

    close() {
        this.hotelsRoomTypeService.resetLocalChanges();
        const routeIndex = this.$route.path.indexOf('/rooms-mapping');
        this.$router.push(this.$route.fullPath.slice(0, routeIndex));
    }
}
